// extracted by mini-css-extract-plugin
export var border1 = "cQ_hJ";
export var border2 = "cQ_hK";
export var border3 = "cQ_hL";
export var border4 = "cQ_hM";
export var container = "cQ_f";
export var dark = "cQ_c1";
export var large = "cQ_bX";
export var ldsRing = "cQ_hN";
export var light = "cQ_c0";
export var medium = "cQ_bY";
export var primaryPressed = "cQ_hP";
export var xlarge = "cQ_hH";