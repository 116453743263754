// extracted by mini-css-extract-plugin
export var centeredTitle = "cV_h2";
export var childrenContainer = "cV_h4";
export var container = "cV_f";
export var dark = "cV_c1";
export var innerContainer = "cV_cq";
export var left = "cV_cJ";
export var light = "cV_c0";
export var noBottomPadding = "cV_hY";
export var noRectangle = "cV_h1";
export var noTopPadding = "cV_hZ";
export var overflowHidden = "cV_h0";
export var right = "cV_cK";
export var subtitle = "cV_k";
export var textContainer = "cV_b7";
export var textContainerCustomWidth = "cV_h3";